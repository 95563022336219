import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const AboutUs = () => {
  return (
    <Layout>
      <Seo
        description="Through use of this web app and taking the C-BARQ (Canine Behavioral Assessment and Research Questionnaire), pet owners receive an in-depth analysis and helpful"
        title="About us"
      />
      <div className="text-2xl pb-16 pt-16">
        <h1 className="text-blue-800 text-3xl md:text-4xl font-semibold mb-4 mt-6">
          What is the PennVet Behavior App?
        </h1>
        <div className="flex flex-col md:flex-row gap-12 mb-4">
          <div className="min-w-[30%]">
            <StaticImage
              // width={300}
              // height={300}
              className="rounded-2xl"
              src="../images/aboutus_doggo.jpg"
              alt="Pen Vet app about us dog"
            />
          </div>
          <div>
            <p className="mb-2">
              Developed in partnership with Intellivets, LLC and the University
              of Pennsylvania School of Veterinary Medicine Center for the
              Interaction of Animals and Society (CIAS), the PennVet Behavior
              App is a platform to perform teleconsults with PennVet
              specialists. Through the use of the app, pet owners and referring
              veterinarians receive an in-depth analysis to further understand
              the behavior of their pet.
            </p>
            <p className="mb-2">
              Referring veterinarians can get started by creating an account,
              inviting their clients to sign up in the app, and request a
              consult.
            </p>
            <p className="mb-2">
              The cost for a consultation is currently $275, and will have a 3-4
              day turnaround.
            </p>
          </div>
        </div>
        <p className="mb-2">
          For more information about the process, please look at the
          instructions for pet owners and veterinarians below.
        </p>
        <p className="mb-2">
          If you have any questions, contact us at{" "}
          <a
            className="text-blue-800 underline"
            href="mailto:hello@intellivets.com"
          >
            hello@intellivets.com
          </a>
        </p>
        <h1 className="text-blue-800 text-3xl md:text-4xl font-semibold mb-4 mt-6">
          What is the C-BARQ?
        </h1>
        <p className="mb-2">
          The C-BARQ (or Canine Behavioral Assessment and Research
          Questionnaire) provides dog owners and professionals with standardized
          evaluations of canine temperament and behavior. It was developed by
          PennVet researchers and is currently the only behavioral assessment
          instrument of its kind extensively tested for reliability and validity
          on large samples of dogs of many breeds. The C-BARQ consists of 100
          questions describing the different ways in which dogs typically
          respond to common events and situations in their environment. The
          C-BARQ is simple to use and can be completed by anyone who is
          reasonably familiar with a dog's typical, day-to-day behavior. On
          average, it takes about 10-15 minutes to complete.
        </p>
        <p className="mb-2">
          The C-BARQ is widely used to evaluate dogs for the presence and
          severity of behavioral problems. For ease of interpretation, each
          dog’s C-BARQ scores on this app are color-coded (green, yellow, orange
          or red) to indicate how far they diverge from the dog population
          average. Scores in the orange-red range are typically concerning.
        </p>
        <h1 className="text-blue-800 text-3xl md:text-4xl font-semibold mb-8 mt-10">
          MEET THE TEAM
        </h1>
        <p className="mb-2 font-semibold text-3xl">James Serpell</p>
        <div className="flex flex-col md:flex-row gap-6 md:gap-12 mb-8 md:mb-12">
          <div className="flex flex-col items-start gap-4 min-w-[300px]">
            <StaticImage
              width={300}
              height={300}
              className="rounded-2xl"
              src="../images/james.jpg"
              alt="James Serpell"
            />
          </div>
          <p className="mb-2">
            James Serpell holds the Marie A. Moore endowed Chair of Animal
            Ethics & Welfare at the School of Veterinary Medicine, University of
            Pennsylvania. He received his bachelor’s degree in Zoology from
            University College London in 1974, and his PhD in Animal Behavior
            from the University of Liverpool (UK) in 1980. At Penn Vet he
            teaches veterinary ethics, applied animal behavior and welfare, and
            human-animal interactions. His research focuses on the behavior and
            welfare of dogs and cats, the development of human attitudes to
            animals, and the history and impact of human-animal relationships,
            and he has published more than 150 articles and book chapters on
            these and related topics. Dr. Serpell is the creator of the Canine
            Behavioral Assessment and Research Questionnaire (C-BARQ), and is
            also the author, editor or co-editor of several books including
            Animals & Human Society: Changing Perspectives (1994), In the
            Company of Animals (1996), Companion Animals & Us (2000), and The
            Domestic Dog (2016).
          </p>
        </div>
        <p className="mb-2 font-semibold text-3xl">Carlo Siracusa</p>
        <div className="flex flex-col md:flex-row gap-6 md:gap-12 mb-8 md:mb-12">
          <div className="flex flex-col items-start gap-4 min-w-[300px]">
            <StaticImage
              width={300}
              height={300}
              className="rounded-2xl"
              src="../images/Carlo1.jpg"
              alt="Carlo Siracusa"
            />
          </div>
          <p className="mb-2">
            Born in Italy, Carlo Siracusa got his DVM from the University of
            Messina, Italy, and his PhD from the Autonomous University of
            Barcelona, Spain, defending a thesis on perioperative stress in dogs
            and the effect of pheromonotherapy. He completed his residency in
            Animal Behavior at the School of Veterinary Medicine of the
            University of Pennsylvania, where he is currently the Director of
            the Animal Behavior Service and Chief of the Primary Care Education
            section. Carlo is board certified by the American College of
            Veterinary Behaviorists, and the European College of Animal Welfare
            and Behavior Medicine. His research interests are focused on stress
            evaluation in dogs, pheromonotherapy, canine temperament testing,
            and prognostic factors and treatment outcome of behavior problems.
          </p>
        </div>
        <h1 className="text-blue-800 text-3xl md:text-4xl font-semibold mb-4 mt-6">
          Our Mission
        </h1>
        <p className="mb-2 font-semibold text-2xl">Dr. KimMi Whitehead, CEO</p>
        <p className="mb-4">
          Dr. KimMi Whitehead, VMD, DACVECC is the chief executive officer of
          Intellivets, and is a Diplomate of the American College of Veterinary
          Emergency and Critical Care (DACVECC). KimMi’s special areas of
          interest include veterinary telehealth, gastrointestinal dysmotility
          in critical care and polytrauma. She is also the co-founder of a
          veterinary online education and training company, Intensivets, LLC.
          She completed her undergraduate studies at Wellesley College before
          beginning graduate school at the University of Pennsylvania School of
          Veterinary Medicine, where she earned her veterinary degree. After
          completing a one-year internship at Animal Surgical Emergency Center
          in Los Angeles, California, KimMi entered a three-year residency in
          Emergency and Critical Care Medicine at Oradell Animal Hospital, a
          specialty/emergency referral hospital in Paramus, New Jersey. She then
          helped establish the critical care department at the Veterinary
          Specialty Center of Delaware. KimMi grew up in Seoul, Korea and speaks
          Korean, English and beginner French. She lives in Philadelphia with
          her husband, daughter, two fluffy dogs, Penny Lane and Eleanor Rigby,
          and one very bossy cat, Sookie.
        </p>
        <p className="mb-2 font-semibold text-2xl">Jacqueline Cooper, COO</p>
        <p className="mb-2">
          As chief operating officer of Intellivets, Jacqueline Cooper is
          responsible for the operations of the company. Her career experience
          and leadership have been focused on developing and delivering
          growth-driven business services and solutions, providing outstanding
          client service, and driving profitable revenue growth for startup
          companies. In 2016, Jacqueline founded Cardona Consulting serving as
          President and Consultant, providing growth-driven business and
          marketing services. Previously, Jacqueline served as Head of Marketing
          for a successful startup spearheading business development, demand
          generation, and marketing strategies contributing to the company’s
          sale. Jacqueline has a Bachelor of Science degree in Marketing
          Management and received her Executive Master of Business
          Administration from Smeal College of Business at Penn State
          University. She serves in board of director and volunteer roles at
          American Marketing Association, PAWS, ASPCA, and Broad Street Homeless
          Outreach Services. Jacqueline lives in Philadelphia with her family
          and beloved Norwegian Forest cat, Rio.
        </p>
      </div>
    </Layout>
  )
}

export default AboutUs
